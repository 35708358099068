import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { STATE_BIS_LOGIN, STATE_BIS_SIGNUP } from 'components/BisAuthForm';
import { useLocation, Link, useHistory } from 'react-router-dom';

import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import BisAuthPage from 'pages/BisAuthPage';
import BisAdminAuthPage from 'pages/BisAdminAuthPage';
import LogOutPage from 'pages/LogOutPage';
import ForgetPasswordPage from 'pages/ForgetPasswordPage';
import PBRPage from 'pages/PBRPage';
import ConfirmEntryTitlePage from 'pages/ConfirmEntryTitlePage';
import ConfirmEntryCollateralPage from 'pages/ConfirmEntryCollateralPage';
import ConfirmEntryEntrantTitlePage from 'pages/ConfirmEntryEntrantTitlePage';
import ConfirmEntryEntrantCollateralPage from 'pages/ConfirmEntryEntrantCollateralPage';
import MaintenancePage from 'pages/MaintenancePage';
// import Maintenance from 'pages/Maintenance';
import AdminFloorPlanPage from 'pages/AdminFloorPlanPage';
import AdminSeatPlanParentPage from 'pages/AdminSeatPlanParentPage';
import React, { useState, useEffect, Suspense } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import './styles/ds-digital.css';
import AdminFloorSeatPlanPage from './pages/AdminFloorSeatPlanPage';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const AwardPage = React.lazy(() => import('pages/AwardPage'));
const CreateAwardPage = React.lazy(() => import('pages/CreateAwardPage'));
// const EntrantDashboard = React.lazy(() => import('pages/EntrantDashboard'));
const EntryPage = React.lazy(() => import('pages/EntryPage'));
const EntryDefaultPage = React.lazy(() => import('pages/EntryDefaultPage'));
const EntryDefaultTestPage = React.lazy(() => import('pages/EntryDefaultTestPage'));
const ProfileViewPage = React.lazy(() => import('pages/ProfileViewPage'));
const CategoryPage = React.lazy(() => import('pages/CategoryPage'));
const BisSetupAwardPage = React.lazy(() => import('pages/BisSetupAwardPage'));
const Booking = React.lazy(() => import('pages/Booking'));
const BookingUnregistered = React.lazy(() => import('pages/BookingUnregistered'));
const BookingDynamic = React.lazy(() => import('pages/BookingDynamic'));
const BookingDynamicNonShortlisted = React.lazy(() => import('pages/BookingDynamicNonShortlisted'));
const BookingDynamicNonShortlistedV2 = React.lazy(() => import('pages/BookingDynamicNonShortlistedV2'));
const BookingDynamicUnregistered = React.lazy(() => import('pages/BookingDynamicUnregistered'));
const BookingDynamicUnregisteredV2 = React.lazy(() => import('pages/BookingDynamicUnregisteredV2'));
const BookingWaitlistFormPage = React.lazy(() => import('pages/BookingWaitlistFormPage'));
const BisSingleAwardPage = React.lazy(() => import('pages/BisSingleAwardPage'));
const BookingSuccess = React.lazy(() => import('pages/BookingSuccess'));
const BookingSuccessV2 = React.lazy(() => import('pages/BookingSuccessV2'));
const BookingWinnerSuccess = React.lazy(() => import('pages/BookingWinnerSuccess'));
const PaymentSuccess = React.lazy(() => import('pages/PaymentSuccess'));
const BookingInvoiceSuccess = React.lazy(() => import('pages/BookingInvoiceSuccess'));
const EntryPaymentSuccess = React.lazy(() => import('pages/EntryPaymentSuccess'));
const SingleEntryPage = React.lazy(() => import('pages/SingleEntryPage'));
const AdminSingleEntryPage = React.lazy(() => import('pages/AdminSingleEntryPage'));
const EditEntryPage = React.lazy(() => import('pages/EditEntryPage'));
const BookingDetailsPage = React.lazy(() => import('pages/BookingDetailsPage'));
const CheckOutPage = React.lazy(() => import('pages/CheckOutPage'));
const CheckOutPageV2 = React.lazy(() => import('pages/CheckOutPageV2'));
const CheckOutUnlistedPage = React.lazy(() => import('pages/CheckOutUnlistedPage'));
const CheckOutUnlistedPageV2 = React.lazy(() => import('pages/CheckOutUnlistedPageV2'));
const BookingsPage = React.lazy(() => import('pages/BookingsPage'));
const BookingAttendeesPage = React.lazy(() => import('pages/BookingAttendeesPage'));
const DashboardEntrantPage = React.lazy(() => import('pages/DashboardEntrantPage'));
// const AdminSingleAwardPage = React.lazy(() => import('pages/AdminSingleAwardPage'));
const AdminUserPage = React.lazy(() => import('pages/AdminUserPage'));
const AdminJudgePage = React.lazy(() => import('pages/AdminJudgePagev2'));
const AdminCoordinatorPage = React.lazy(() => import('pages/AdminCoordinatorPagev2'));
const EntriesPage = React.lazy(() => import('pages/EntriesPage'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage'));
const EditProfilePage = React.lazy(() => import('pages/EditProfilePage'));
const BookingAttendeesSetupPage = React.lazy(() => import('pages/BookingAttendeesSetupPage'));
const BookingUnlisted = React.lazy(() => import('pages/BookingUnlisted'));
const AdminDashboardPage = React.lazy(() => import('pages/AdminDashboardPage'));
const AdminUserManagementPage = React.lazy(() => import('pages/AdminUserManagementPagev2'));
const AdminAwardsPage = React.lazy(() => import('pages/AdminAwardsPage'));
const AdminAwardTypesPage = React.lazy(() => import('pages/AdminAwardTypesPage'));
const AdminSingleAwardPage = React.lazy(() => import('pages/AdminSingleAwardPage'));
const AdminSingleCategoryPage = React.lazy(() => import('pages/AdminSingleCategoryPage'));
const AdminEntriesPage = React.lazy(() => import('pages/AdminEntriesPage'));
const AdminSurveysPage = React.lazy(() => import('pages/AdminSurveysPage'));
const CreateCategoryPage = React.lazy(() => import('pages/CreateCategoryPage'));
const AdminBookingsPage = React.lazy(() => import('pages/AdminBookingsPage'));
const ViewProfilePage = React.lazy(() => import('pages/ViewProfilePage'));
const AdminEditProfilePage = React.lazy(() => import('pages/AdminEditProfilePage'));
const AdminBookingDetailsPage = React.lazy(() => import('pages/AdminBookingDetailsPage'));
const EntrantJudgingPanelPage = React.lazy(() => import('pages/EntrantJudgingPanelPage'));
const JudgeProfilePage = React.lazy(() => import('pages/JudgeProfilePage'));
const EntrantShortlistBenefitsPage = React.lazy(() => import('pages/EntrantShortlistBenefitsPage'));
const CoordinatorProfilePage = React.lazy(() => import('pages/CoordinatorProfilePage'));
const JudgeCategoryPage = React.lazy(() => import('pages/JudgeCategoryPage'));
const JudgeEntryPage = React.lazy(() => import('pages/JudgeEntryPage'));
const JudgeJudgingPage = React.lazy(() => import('pages/JudgeJudgingPage'));
const JudgeAssignmentPage = React.lazy(() => import('pages/JudgeAssignmentPage'));
const JudgeAssignmentCategoryPage = React.lazy(() => import('pages/JudgeAssignmentCategoryPage'));
const CoordinatorCategoryPage = React.lazy(() => import('pages/CoordinatorCategoryPage'));
const CoordinatorAssignmentsPage = React.lazy(() => import('pages/CoordinatorAssignmentsPage'));
const CoordinatorAssignmentPage = React.lazy(() => import('pages/CoordinatorAssignmentPage'));
const CoordinatorAssignmentCategoryPage = React.lazy(() => import('pages/CoordinatorAssignmentCategoryPage'));
const CoordinatorShortlistingPage = React.lazy(() => import('pages/CoordinatorShortlistingPage'));
const JudgingCoordinatorReferralPage = React.lazy(() => import('pages/JudgingCoordinatorReferralPage'));
const InvoicesPage = React.lazy(() => import('pages/InvoicesPage'));
const AdminJudgingResults = React.lazy(() => import('pages/AdminJudgingResults'));
const AdminsPage = React.lazy(() => import('pages/AdminsPage'));
const BookingAwardWinner = React.lazy(() => import('pages/BookingAwardWinner'));
const CheckOutAwardWinnerPage = React.lazy(() => import('pages/CheckOutAwardWinnerPage'));
const AdminEntryEditPage = React.lazy(() => import('pages/AdminEntryEditPage'));
const AdminsV2Page = React.lazy(() => import('pages/AdminsV2Page'));
const NotificationPage = React.lazy(() => import('pages/NotificationPage'));
const AdminInvoicesPage = React.lazy(() => import('pages/AdminInvoicesPage'));
const AdminPriceQuotePage = React.lazy(() => import('pages/AdminPriceQuotePage'));
const AdminPriceQuotesPage = React.lazy(() => import('pages/AdminPriceQuotesPage'));
const AdminBookingRequestPage = React.lazy(() => import('pages/AdminBookingRequestPage'));
const AdminCreatePriceQuotePage = React.lazy(() => import('pages/AdminCreatePriceQuotePage'));
const EnteringAwardPage = React.lazy(() => import('pages/EnteringAwardPage'));
const AdminBookingAttendeesPage = React.lazy(() => import('pages/AdminBookingAttendeesPage'));
const InvolvementPage = React.lazy(() => import('pages/InvolvementPage'));
const EntrantCategoryPage = React.lazy(() => import('pages/EntrantCategoryPage'));
const AwardEventInformation = React.lazy(() => import('pages/AwardEventInformation'));
const BeASponsorPage = React.lazy(() => import('pages/BeASponsorPage'));
const BeAJudgePage = React.lazy(() => import('pages/BeAJudgePage'));
// const JudgeHomePage = React.lazy(() => import('pages/JudgeHomePage'));
const JudgeTermsAndConditionPage = React.lazy(() => import('pages/JudgeTermsAndConditionPage'));
const JudgeReferralPage = React.lazy(() => import('pages/JudgeReferralPage'));
const JudgeJudgingCategoryPage = React.lazy(() => import('pages/JudgeJudgingCategoryPage'));
const EntrantSingleEntryPage = React.lazy(() => import('pages/EntrantSingleEntryPage'));
const EntrantCategoriesPage = React.lazy(() => import('pages/EntrantCategoriesPage'));
const EntrantBookingsPage = React.lazy(() => import('pages/EntrantBookingsPage'));
const EntrantEntriesPage = React.lazy(() => import('pages/EntrantEntriesPage'));
const SocialMediaPage = React.lazy(() => import('pages/SocialMediaPage'));
const GalleryPage = React.lazy(() => import('pages/GalleryPage'));
const CoordinatorAssignmentEntriesPage = React.lazy(() => import('pages/CoordinatorAssignmentEntriesPage'));
const JudgeShortlistingPage = React.lazy(() => import('pages/JudgeShortlistingPage'));
const BookingV2Page = React.lazy(() => import('pages/BookingV2Page'));
const EntrantBookingPage = React.lazy(() => import('pages/EntrantBookingPage'));
const EntrantPriceQuotePage = React.lazy(() => import('pages/EntrantPriceQuotePage'));
const CoordinatorTermsAndConditionPage = React.lazy(() => import('pages/CoordinatorTermsAndConditionPage'));
const EntrantPrintManagementPage = React.lazy(() => import('pages/EntrantPrintManagementPage'));
const EntrantArtworkPage = React.lazy(() => import('pages/EntrantArtworkPage'));
const AdminProcurementPage = React.lazy(() => import('pages/AdminProcurementPage'));
const AdminProcurementPageV2 = React.lazy(() => import('pages/AdminProcurementPageV2'));
const AdminArtWorkPage = React.lazy(() => import('pages/AdminArtWorkPage'));
const AdminArtWorkPageV2 = React.lazy(() => import('pages/AdminArtWorkPageV2'));
const AdminArtWorkTypePage = React.lazy(() => import('pages/AdminArtWorkTypePage'));
const AdminArtWorkEditPage = React.lazy(() => import('pages/AdminArtWorkEditPage'));  
const EntrantHomePage = React.lazy(() => import('pages/EntrantHomePage'));
const EntrantEntrySupportPage = React.lazy(() => import('pages/EntrantEntrySupportPage'));
const EntrantPreConsultationPage = React.lazy(() => import('pages/EntrantPreConsultationPage'));
const EntrantChatPage = React.lazy(() => import('pages/EntrantChatPage'));
const AdminAwardChatsPage = React.lazy(() => import('pages/AdminAwardChatsPage'));
const AdminAwardChatPage = React.lazy(() => import('pages/AdminAwardChatPage'));
const EntrantWinningEntryPage = React.lazy(() => import('pages/EntrantWinningEntryPage'));
const EntrantJudgeScoreCardPage = React.lazy(() => import('pages/EntrantJudgeScoreCardPage'));
const EntrantSampleWinningEntryPage = React.lazy(() => import('pages/EntrantSampleWinningEntryPage'));
const EntrantAwardProfilePage = React.lazy(() => import('pages/EntrantAwardProfilePage'));
const EntrantFloorPlanPage = React.lazy(() => import('pages/EntrantFloorPlanPage'));
const JudgeCoordinatorHomePage = React.lazy(() => import('pages/JudgeCoordinatorHomePage'));
const JudgeHomePage = React.lazy(() => import('pages/JudgeHomePage'));
const JudgeCategoriesPage = React.lazy(() => import('pages/JudgeCategoriesPage'));
const JudgingCoordinatorPage = React.lazy(() => import('pages/JudgingCoordinatorPage'));
const JudgeFellowJudgesPage = React.lazy(() => import('pages/JudgeFellowJudgesPage'));
const JudgeComplimentarySeatsPage = React.lazy(() => import('pages/JudgeComplimentarySeatsPage'));
const JudgeInstructionsPage = React.lazy(() => import('pages/JudgeInstructionsPage'));
const JudgeNominationPage = React.lazy(() => import('pages/JudgeNominationPage'));
const JudgingCoordinatorHomePage = React.lazy(() => import('pages/JudgingCoordinatorHomePage'));
const JudgingCoordinatorProfilePage = React.lazy(() => import('pages/JudgingCoordinatorProfilePage'));
const JudgingCoordinatorShortlistingPage = React.lazy(() => import('pages/JudgingCoordinatorShortlistingPage'));
const JudgingCoordinatorShortlistingCategoryPage = React.lazy(() => import('pages/JudgingCoordinatorShortlistingCategoryPage'));
const JudgingCoordinatorJudgesPage = React.lazy(() => import('pages/JudgingCoordinatorJudgesPage'));
const JudgingCoordinatorFellowCoordinatorsPage = React.lazy(() => import('pages/JudgingCoordinatorFellowCoordinatorsPage'));
const JudgingCoordinatorInstructionsPage = React.lazy(() => import('pages/JudgingCoordinatorInstructionsPage'));
const JudgingCoordinatorComplimentarySeatsPage = React.lazy(() => import('pages/JudgingCoordinatorComplimentarySeatsPage'));
const BookingAwardEventPage = React.lazy(() => import('pages/BookingAwardEventPage'));
const GalleryHomePage = React.lazy(() => import('pages/GalleryHomePage'));
const GallerySelectionsPage = React.lazy(() => import('pages/GallerySelectionsPage'));
const SocialMediaConnectedAccountsPage = React.lazy(() => import('pages/SocialMediaConnectedAccountsPage'));
const GallerySelectionPage = React.lazy(() => import('pages/GallerySelectionPage'));
const JudgingCoordinatorJudgeAllocationResultsPage = React.lazy(() => import('pages/JudgingCoordinatorJudgeAllocationResultsPage'));
const GalleryTimelinePage = React.lazy(() => import('pages/GalleryTimelinePage'));
const AdminGalleryUploadPage = React.lazy(() => import('pages/AdminGalleryUploadPage'));
const StakeholdersPage = React.lazy(() => import('pages/StakeholdersPage'));
const StakeholdersArtworkPage = React.lazy(() => import('pages/StakeholdersArtworkPage'));
const AdminJudgeAllocationPage = React.lazy(() => import('pages/AdminJudgeAllocationPage'));
const AdminEventInformationPage = React.lazy(() => import('pages/AdminEventInformationPage'));
const AdminVenueSeatingPage = React.lazy(() => import('pages/AdminVenueSeatingPage'));
const AdminVenuePlanPage = React.lazy(() => import('pages/AdminVenuePlanPage'));
const AdminVenuePage = React.lazy(() => import('pages/AdminVenuePage'));
const AdminVenueSettingsPage = React.lazy(() => import('pages/AdminVenueSettingsPage'));
const AdminVenuesPage = React.lazy(() => import('pages/AdminVenuesPage'));
// const AdminSeatPlanPage = React.lazy(() => import('pages/AdminSeatPlanPage'));
const AdminVenueGuestlistPage = React.lazy(() => import('pages/AdminVenueGuestlistPage'));
const AdminAwardMediaLibraryPage = React.lazy(() => import('pages/AdminAwardMediaLibraryPage'));

// const AdminEditSeatingVenuePage = React.lazy(() => import('pages/AdminEditSeatingVenuePage'));
const EntrantManageMyAttendeesPage = React.lazy(() => import('pages/EntrantManageMyAttendeesPage'));
const AdminWinnersListPage = React.lazy(() => import('pages/AdminWinnersListPage'));
const AdminEntriesListPage = React.lazy(() => import('pages/AdminEntriesListPage'));
const AdminCreateInvoicePage = React.lazy(() => import('pages/AdminCreateInvoicePage'));
const BankTransferPage = React.lazy(() => import('pages/BankTransferPage'));
const BankTransferTermsAndConditionPage = React.lazy(() => import('pages/BankTransferTermsAndConditionPage'));
const AdminBankTransfersPage = React.lazy(() => import('pages/AdminBankTransfersPage'));
const AdminBookingFormsPage = React.lazy(() => import('pages/AdminBookingFormsPage'));
const AdminTicketsPage = React.lazy(() => import('pages/AdminTicketsPagev2'));
const AdminSingleTicketPage = React.lazy(() => import('pages/AdminSingleTicketPage'));
// const AdminBankTransfersPage = React.lazy(() => import('pages/AdminBankTransfersPage'));
const TicketsPage = React.lazy(() => import('pages/TicketsPage'));
const EntrantPerformanceBenchmarkReportPage = React.lazy(() => import('pages/EntrantPerformanceBenchmarkReportPage'));
const AdminShortlistsPage = React.lazy(() => import('pages/AdminShortlistsPage'));
const AdminAwardArticlesPage = React.lazy(() => import('pages/AdminAwardArticlesPage'));
const AdminAwardArticlesAddPage = React.lazy(() => import('pages/AdminAwardArticlesAddPage'));
const AdminAwardArticlesEditPage = React.lazy(() => import('pages/AdminAwardArticlesEditPage'));
const AdminNotificationsTriggerPage = React.lazy(() => import('pages/AdminNotificationTrigger'));
const AdminNotificationFilePage = React.lazy(() => import('pages/AdminNotificationFilePage'));
const AdminPreConsultationPage = React.lazy(() => import('pages/AdminPreConsultationPage'));
const AdminCollateralsPage = React.lazy(() => import('pages/AdminCollateralsPage'));
const AdminCollateralPage = React.lazy(() => import('pages/AdminCollateralPage'));
const AdminPagePlanPage = React.lazy(() => import('pages/AdminPagePlanPage'));
const AdminPagePlanEditPage = React.lazy(() => import('pages/AdminPagePlanEditPage'));
const AdminArtworkCollateralsPage = React.lazy(() => import('pages/AdminArtworkCollateralsPage'));
const AdminArtwokCollateralPage = React.lazy(() => import('pages/AdminArtwokCollateralPage'));
const AdminPageItemPage = React.lazy(() => import('pages/AdminPageItemPage'));
const DesignerProcurementPage = React.lazy(() => import('pages/DesignerProcurementPage'));
const DesignerArtworkPage = React.lazy(() => import('pages/DesignerArtworkPage'));
const DesignerPagePlanPage = React.lazy(() => import('pages/DesignerPagePlanPage'));
const DesignerPageItemPage = React.lazy(() => import('pages/DesignerPageItemPage'));
const PrinterProcurementPage = React.lazy(() => import('pages/PrinterProcurementPage'));
const PrinterArtworkPage = React.lazy(() => import('pages/PrinterArtworkPage'));
const PrinterPagePlanPage = React.lazy(() => import('pages/PrinterPagePlanPage'));
const PrinterPageItemPage = React.lazy(() => import('pages/PrinterPageItemPage'));
const AdminScriptGeneratorPage = React.lazy(() => import('pages/AdminScriptGeneratorPage'));
const AdminScriptTemplate = React.lazy(() => import('pages/AdminScriptTemplate'));
const AdminScriptCategoryOrder = React.lazy(() => import('pages/AdminScriptCategoryOrder'));
const AdminScriptJudgeComments = React.lazy(() => import('pages/AdminScriptJudgeComments'));
const AdminScriptFinal = React.lazy(() => import('pages/AdminScriptFinal'));
const AdminScriptVOPage = React.lazy(() => import('pages/AdminScriptVOPage'));
const ScriptBlurbPage = React.lazy(() => import('pages/ScriptBlurbPage'));
const AdminAwardCMSCreatePage = React.lazy(() => import('pages/AdminAwardCMSCreatePage'));
const AdminAwardCMSSettingsPage = React.lazy(() => import('pages/AdminAwardCMSSettingsPage'));
const AdminEntryGuideGeneratorPage = React.lazy(() => import('pages/AdminEntryGuideGeneratorPage'));
const AdminEventGuidePage = React.lazy(() => import('pages/AdminEventGuidePage'));
const AdminAwardCMSThemePage = React.lazy(() => import('pages/AdminAwardCMSThemePage'));
const AdminAwardCMSMenuPage = React.lazy(() => import('pages/AdminAwardCMSMenuPage'));
const AdminAwardCMSPagesPage = React.lazy(() => import('pages/AdminAwardCMSPagesPage'));
const AdminAwardCMSSocialMediaPage = React.lazy(() => import('pages/AdminAwardCMSSocialMediaPage'));
const AdminAwardInquiriesPage = React.lazy(() => import('pages/AdminAwardInquiriesPage'));
const AdminSponsorshipTypesPage = React.lazy(() => import('pages/AdminSponsorshipTypesPage'));
const AdminSingleSponsorshipTypePage = React.lazy(() => import('pages/AdminSingleSponsorshipTypePage'));
const AdminSponsorshipFeaturesPage = React.lazy(() => import('pages/AdminSponsorshipFeaturesPage'));
const ErrorInvitationPage = React.lazy(() => import('pages/ErrorInvitationPage'));
const SponsorHomePage = React.lazy(() => import('pages/SponsorHomePage'));
const SponsorshipAgreementPage = React.lazy(() => import('pages/SponsorshipAgreementPage'));
const SponsorshipManagementPage = React.lazy(() => import('pages/SponsorshipManagementPage'));
const SponsorshipActivationPage = React.lazy(() => import('pages/SponsorshipActivationPage'));
const SponsorshipDashboardPage = React.lazy(() => import('pages/SponsorshipDashboardPage'));
const AdminSponsorsPage = React.lazy(() => import('pages/AdminSponsorsPage'));
const AdminSponsorsDashboardPage = React.lazy(() => import('pages/AdminSponsorsDashboardPage'));
const AdminSponsorsFilesPage = React.lazy(() => import('pages/AdminSponsorsFilesPage'));
const AdminAddSponsorPage = React.lazy(() => import('pages/AdminAddSponsorPage'));
const AdminViewSponsorPage = React.lazy(() => import('pages/AdminViewSponsorPage'));
const AdminEditSponsorPage = React.lazy(() => import('pages/AdminEditSponsorPage'));
const AdminActivationSponsorPage = React.lazy(() => import('pages/AdminActivationSponsorPage'));
const EntrantSubmitEntryPage = React.lazy(() => import('pages/EntrantSubmitEntryPage'));
const EntrantConfirmTrophyRecipientPage = React.lazy(() => import('pages/EntrantConfirmTrophyRecipientPage'));
const AdminSponsorActivityPage = React.lazy(() => import('pages/AdminSponsorActivityPage'));
const AdminAwardBookingsPage = React.lazy(() => import('pages/AdminAwardBookingsPage'));
const PublicNominationFormPage = React.lazy(() => import('pages/PublicNominationFormPage'));
const PageNotFound = React.lazy(() => import('pages/PageNotFound'));
const Stripe = React.lazy(() => import('pages/StripePage'));
const StripeInvoice = React.lazy(() => import('pages/StripeInvoicePage'));
const StripeUpsell = React.lazy(() => import('pages/StripeUpsellPage'));
const StripeEntry = React.lazy(() => import('pages/StripeEntryPage'));
const AdminAwardAdvertisement = React.lazy(() => import('pages/AdminAwardAdvertisement'));
const AdminAwardPlacementAddPage = React.lazy(() => import('pages/AdminAwardPlacementAddPage'));
const AdminAwardAdvertisementAddPage = React.lazy(() => import('pages/AdminAwardAdvertisementAddPage'));

const PriceQuoteSummaryPage = React.lazy(() => import('pages/PriceQuoteSummaryPage'));
const AdminProjectTypesPage = React.lazy(() => import('pages/AdminProjectTypesPage'));
const AdminSuppliersPage = React.lazy(() => import('pages/AdminSuppliersPage'));
const AdminProcurementPageV3 = React.lazy(() => import('pages/AdminProcurementPageV3'));
const AdminProcurementsPageV3 = React.lazy(() => import('pages/AdminProcurementsPageV3'));
const AdminProjectNewInvoicePage = React.lazy(() => import('pages/AdminProjectNewInvoicePage'));
const AdminProjectInvoicePage = React.lazy(() => import('pages/AdminProjectInvoicePage'));
const AdminSupplierProjectsPage = React.lazy(() => import('pages/AdminSupplierProjectsPage'));

const AdminBlacklistPage = React.lazy(() => import('pages/AdminBlacklistPage'));
const AdminEmailTesterPage = React.lazy(() => import('pages/AdminEmailTesterPage'));

// print management backup
const AdminProcurementPageBackUp = React.lazy(() => import('pages/AdminProcurementPageBackUp'));
const AdminArtWorkPageBackUp = React.lazy(() => import('pages/AdminArtWorkPageBackUp'));

//Admin Post Event Feedback pages
const AdminAwardPostEventFeedBackPage = React.lazy(() => import('pages/AdminAwardPostEventFeedBackPage'));
const AdminAwardPostEventFeedBackAdminPage = React.lazy(() => import('pages/AdminAwardPostEventFeedBackAdminPage'));
const AdminAwardSinglePostEventFeedBackPage = React.lazy(() => import('pages/AdminAwardSinglePostEventFeedBackPage'));

const AdminIndustriesPage = React.lazy(() => import('pages/AdminIndustriesPage'));
const AdminIndustryAwardTypesPage = React.lazy(() => import('pages/AdminIndustryAwardTypesPage'));
const AdminAwardTypesAwardPage = React.lazy(() => import('pages/AdminAwardTypesAwardPage'));

const Maintenance = React.lazy(() => import('pages/Maintenance'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function AppMain(props) {

  const breakpoint = props.breakpoint;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const award_code = urlParams.get('award_code');
  const test = urlParams.get('test');

  const awardKey = {
    // TEST: 'pk_test_51KH5FQEHRDNky8yNuVaslaQXG2zhzUjBuooEw7vp8LKMwMd5eEd5xt5RAL0UdiuVJf7dMAwllXdSiDkvvSp9qzT700fhQD4wrQ',
    // STRIPE_KEY: 'pk_live_51JMZpxBaAa9kORLQxVlQT5f7wrzW2GFD69206bBscB7xC90bouweXU3QAqb1KtM6XJC9NXMvnFWShMkuMZEWluIW005s8NnHbH',
    HS_OLD: 'pk_live_RE7igObaFC4w1Di9Th6JhNoj00loL11ld8',
    'H&S': 'pk_live_RE7igObaFC4w1Di9Th6JhNoj00loL11ld8',
    HS: 'pk_live_RE7igObaFC4w1Di9Th6JhNoj00loL11ld8',
    BAYA: 'pk_live_MH6KYf2Chwl8aOvkv2Cc9cTN00dJLi2Ksa',
    IAA: 'pk_live_m2LCdlClXNnszt6O1cRTKius',
    OPEX: 'pk_live_51IiKkBFIo424glzTPC7tg2mW4d3SRBVQRdmFOT1VFTY0tEArXc3PaoD3mtS797i5i3ZuoUmMsunHojfffQGioGrM007Vx0mSad',
    NPA: 'pk_live_51HcwDjAY0ITgp8er1sYxJF6jww3p2WwQF4KyScqPx5pilsHBbUgQqboiETGPAaNeczRlAz3PMyKcp81pJFV7QlwA00kWmff0h5',
    ISA: 'pk_live_51LIAIlKIcEff4Z3w6hXuXHfhQcmL4NnDc9EULk7dIAt2pxy3q6zV0jv3aLU1TPIYIxhY0bRp03Pcp2OELwUn0VE200cWJN3459',
    HR: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    ICIA: 'pk_live_PIXFKl9OmmdgmUDGEDhwkm9C004XM5ftGG',
    WCA: 'pk_live_51OlSUhAC8A52GoioMK7pInbjapOgV94XzcZtGIQWLpIfd15VjZbHXeCvN6RHqxKhajDTtR1UhJLB6N3s11UDfDrl00zkCXKaHD',
    PHARMA: 'pk_live_viHL2ydQYyekh5aVoTqOp6qg00xCjqrNbB',
    CX: 'pk_live_ZDpqCjgprqfMspN2kqIItbHi00ibqbRrOS',
    FOA: 'pk_live_q85f499zgJuZ5wKonWsrUP0M002QxHWn9E',
    FOA_ENTRY: 'pk_live_51JNLcfIgB2g8plRvGFR7Y2Ra6mMqULYWq8zEgHE7pQ7TFW9NYLrB3uqSTkXDTrBoZqapBYRtYYphDVY8TbX57P9z00qBWzHaGs',
    GA: 'pk_live_p77WT6ZJoOQNBr8YHNSBd2Je005mUTo7Vl',
    FM: 'pk_live_Na4ulaE85hBlLLVFjrfeoiWV',
    ILTA: 'pk_live_6ScL12MV518azC7wEP2A8pfg005gKOG9BQ',
    ED: 'pk_live_0ULC3w76eGIKrwWDXx2GgBj900mlTK8vRj',
    LSA: 'pk_live_51N0PEaJG4X97X3bS0RNp3NRuOHXrxFEKOhpO6D4oc8XFDS8nvkQpp6gUYVy1aPGeIRnVhA5iWUE96a0bDnKgWkTe00q11tHGde',
    LSAA: 'pk_live_m2LCdlClXNnszt6O1cRTKius',
    BEAA: 'pk_live_51JmyfdIsqUJthTbxAypw6zkcXOrC7xG0bqzOGfvr9EjVsgo3UU5X9yMYbfgzOggfrKDJqMCMy0hBGXFtakHEJtSJ00abezyUMC',
    PIAUK: 'pk_live_51NZoIQD9ziGat01usye5VP2hazlDn0tcCXd6MylBtPmKxKlOBOgiRHZmZPRWBhtkdULFwhzkHPWiX5B3qVuES4Qx00BpPpoNNH',
    // PIAUK: 'pk_live_51NZoIQD9ziGat01usye5VP2hazlDn0tcCXd6MylBtPmKxKlOBOgiRHZmZPRWBhtkdULFwhzkHPWiX5B3qVuES4Qx00BpPpoNNH',
    // AIA: 'pk_live_8evxRIB2xtqRbdRaD5wWdgia00eAhBrzNp',
    AIA: 'pk_live_8evxRIB2xtqRbdRaD5wWdgia00eAhBrzNp',
    AIAUK: 'pk_live_51Pds8lJvf8nCoabNN1ohxTufk7nKpestTUxSu6EybzLR9w0n1MHjwMAtwnaTADxErQbb4s2wQ85YYbqLDm0xikxi0027dCUQ1A',
  
    EIA: 'pk_live_uXPZS8wm5RSEYVBWti99dPrg00r09UENcL',
    EEA: 'pk_live_51PgJ09FUdwRqQ0xUdJd49hNvvucYaE5hbvtBY5JRdoxDKBezPyYBsMJBnYOzHkObYlThaKTjv0uZi2k12LSlnONq00Oe9wiUw4',
    PHCA: 'pk_live_51PgIqGB5gqH5nfLe47ZDvq0HjZEam3ACEOaaWQzO9oEBFW9k9Ghv8YVLbtuslCc6oe0bTCa1mHmCXgYWKPnPJIga00YJaBzF5f',

    ICMA: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    IPL: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    LAB: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    PL: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    GFB: 'pk_live_HXVu95yF95atNSC0Kenevm2M',
    MEA: 'pk_live_51OlRscDD7QX5gl5xoUeoveqjPGPsyFYdfU0QR1hSZ0DrM0pKdI4PozlrzD06P23eeFkQOnN6XOF8Jv2vbmer2Ygp00uDJsBE6t',
    FOAUK: 'pk_live_51Pdw6bDc6HIXmvr4SpFqbpcLX81AdQlilhGN33RrakaSZ94o8u5OcO4avCHWnrV5AJhIYjmaAjBr7etXNXGuDjCM00cWAbPzR2',
    AAIA: 'pk_live_51OlSGpGaxWvNsfz5cvbqG8JvcDX1dSV1yiwea4jps2ZTs1mwN5UKqHn5onItVPoa0Hhr9HRsuY2pM7FKRFkzlvPW00vUIY3JzV',
    DTA: 'pk_live_51OlSfPA2ZPceZ2ZP2o3vjyTgbMo7fc0wA7fg4MbSsm3eCYyCu2CXa8sP6Z4cLZDtbvr4N6RtfEww62jNd8NMQO5Y00vXyVsXA8',
  }

  const awardEntryKey = {
    FOA: 'pk_live_51JNLcfIgB2g8plRvGFR7Y2Ra6mMqULYWq8zEgHE7pQ7TFW9NYLrB3uqSTkXDTrBoZqapBYRtYYphDVY8TbX57P9z00qBWzHaGs',
  }

  const defaultKey = 'pk_live_HXVu95yF95atNSC0Kenevm2M';
  const testKey = 'pk_test_51KH5FQEHRDNky8yNuVaslaQXG2zhzUjBuooEw7vp8LKMwMd5eEd5xt5RAL0UdiuVJf7dMAwllXdSiDkvvSp9qzT700fhQD4wrQ';

  return (
    <>
      {window.location.pathname === '/stripe'?
        <Suspense fallback={<div></div>}>
          <BrowserRouter>
            <Switch>
              <Elements stripe={loadStripe(test ? testKey : awardKey[award_code] ? awardKey[award_code] : defaultKey)}>
                <Route exact path="/stripe" component={Stripe} />
              </Elements>
            </Switch>
          </BrowserRouter>
        </Suspense>
        : window.location.pathname === '/invoice/stripe'?
          <Suspense fallback={<div></div>}>
            <BrowserRouter>
              <Switch>
                <Elements stripe={loadStripe(test ? testKey : awardKey[award_code] ? awardKey[award_code] : defaultKey)}>
                  <Route exact path="/invoice/stripe" component={StripeInvoice} />
                </Elements>
              </Switch>
            </BrowserRouter>
          </Suspense>
          : window.location.pathname === '/winner/stripe'?
            <Suspense fallback={<div></div>}>
              <BrowserRouter>
                <Switch>
                  <Elements stripe={loadStripe(test ? testKey : awardKey[award_code] ? awardKey[award_code] : defaultKey)}>
                    <Route exact path="/winner/stripe" component={StripeUpsell} />
                  </Elements>
                </Switch>
              </BrowserRouter>
            </Suspense>
            : window.location.pathname === '/entry/stripe'?
              <Suspense fallback={<div></div>}>
                <BrowserRouter>
                  <Switch>
                    <Elements stripe={loadStripe(test ? testKey : awardEntryKey[award_code] ? awardEntryKey[award_code] : defaultKey)}>
                      <Route exact path="/entry/stripe" component={StripeEntry} />
                    </Elements>
                  </Switch>
                </BrowserRouter>
              </Suspense>
        :
        <BrowserRouter basename={getBasename()}>
          <GAListener>
            <Switch>
              {/* <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          /> */}
              <LayoutRoute
                exact
                path="/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/entrant/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/judge/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/coordinator/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/designer/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/printer/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/sponsor/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/admin/login"
                layout={EmptyLayout}
                component={props => (
                  <BisAdminAuthPage {...props} authState={STATE_BIS_LOGIN} />
                )}
              />

              <LayoutRoute
                exact
                path="/maintenance"
                layout={EmptyLayout}
                component={props => (
                  <MaintenancePage />
                )}
              />
              <LayoutRoute
                exact
                path="/admin/venue/floor-plan"
                layout={EmptyLayout}
                component={props => (
                  <AdminFloorPlanPage />
                )}
              />
              <LayoutRoute
                exact
                path="/venue/floor-plan"
                layout={EmptyLayout}
                component={props => (
                  <AdminFloorPlanPage />
                )}
              />
              <LayoutRoute
                exact
                path="/admin/venue/seat-plan"
                layout={EmptyLayout}
                component={props => (
                  <AdminSeatPlanParentPage />
                )}
              />
              <LayoutRoute
                exact
                path="/venue/seat-plan"
                layout={EmptyLayout}
                component={props => (
                  <AdminSeatPlanParentPage />
                )}
              />
          <LayoutRoute
            exact
            path="/venue/plan"
            layout={EmptyLayout}
            component={props => (
              <AdminFloorSeatPlanPage />
            )}
          />
          
          <LayoutRoute
            exact
            path="/report"
            layout={EmptyLayout}
            component={props => (
              <PBRPage />
            )}
          />
          <LayoutRoute
            exact
            path="/forgotpassword"
            layout={EmptyLayout}
            component={props => (
              <ForgetPasswordPage />
            )}
          />
          <LayoutRoute
            exact
            path="/register"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/entrant/register"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/judge/register"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/coordinator/register"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/logout"
            layout={EmptyLayout}
            component={props => (
              <LogOutPage />
            )}
          />
          <LayoutRoute
            exact
            path="/confirm/entry-title"
            layout={EmptyLayout}
            component={props => (
              <ConfirmEntryTitlePage />
            )}
          />
          <LayoutRoute
            exact
            path="/confirm/entry-collateral"
            layout={EmptyLayout}
            component={props => (
              <ConfirmEntryCollateralPage />
            )}
          />
          <LayoutRoute
            exact
            path="/confirm/shortlist/entry-title"
            layout={EmptyLayout}
            component={props => (
              <ConfirmEntryEntrantTitlePage />
            )}
          />
          <LayoutRoute
            exact
            path="/confirm/shortlist/entry-collateral"
            layout={EmptyLayout}
            component={props => (
              <ConfirmEntryEntrantCollateralPage />
            )}
          />
          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route exact path="/login-modal" component={AuthModalPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/cards" component={CardPage} />
              <Route exact path="/widgets" component={WidgetPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/alerts" component={AlertPage} />
              <Route exact path="/tables" component={TablePage} />
              <Route exact path="/badges" component={BadgePage} />
              <Route exact path="/button-groups" component={ButtonGroupPage} />
              <Route exact path="/dropdowns" component={DropdownPage} />
              <Route exact path="/progress" component={ProgressPage} />
              <Route exact path="/modals" component={ModalPage} />
              <Route exact path="/forms" component={FormPage} />
              <Route exact path="/input-groups" component={InputGroupPage} />
              <Route exact path="/charts" component={ChartPage} />
              <Route exact path="/designer/home" component={DesignerProcurementPage} />
              <Route exact path="/designer/artwork" component={DesignerArtworkPage} />
              <Route exact path="/designer/page-plan" component={DesignerPagePlanPage} />
              <Route exact path="/designer/page-item" component={DesignerPageItemPage} />
              <Route exact path="/printer/home" component={PrinterProcurementPage} />
              <Route exact path="/printer/artwork" component={PrinterArtworkPage} />
              <Route exact path="/printer/page-plan" component={PrinterPagePlanPage} />
              <Route exact path="/printer/page-item" component={PrinterPageItemPage} />
              <Route exact path="/admin/award/create" component={CreateAwardPage} />
              <Route exact path="/award/entry" component={EntryPage} />
              <Route exact path="/profile-old" component={ProfileViewPage} />
              <Route exact path="/profile" component={ProfilePage} />
              <Route exact path="/edit-profile" component={EditProfilePage} />
              <Route exact path="/award/category" component={CategoryPage} />
              <Route exact path="/award/bis-setup" component={BisSetupAwardPage} />
              <Route exact path="/award/bis-single-award" component={BisSingleAwardPage} />
              
              {/* TODO: to make the all awards to dynamic version (V1) */}
              <Route exact path="/award/booking/v1" component={Booking} />
              <Route exact path="/award/book/v1" component={BookingUnregistered} />
              <Route exact path="/award/book/seats/v1" component={BookingUnlisted} />
              <Route exact path="/award/booking/form" component={BookingWaitlistFormPage} />
  
              {/* TODO: to make the all awards to dynamic version (LIVE) */}
              <Route exact path="/award/booking" component={BookingDynamicUnregisteredV2} />
              <Route exact path="/award/book" component={BookingDynamicUnregisteredV2} />
              <Route exact path="/award/book/seats" component={BookingDynamicNonShortlistedV2} />
              <Route exact path="/award/book/form" component={BookingDynamicUnregistered} />
              <Route exact path="/award/book/dynamic" component={BookingDynamicUnregistered} />
              <Route exact path="/award/book/form/seats" component={BookingDynamicNonShortlisted} />
              
              <Route exact path="/award/book/seats/v2" component={BookingDynamicNonShortlisted} />
              <Route exact path="/award/booking/v2" component={BookingDynamicUnregistered} />
              <Route exact path="/award/book/v2" component={BookingDynamicUnregistered} />

              <Route exact path="/award/winner" component={BookingAwardWinner} />
              <Route exact path="/booking/payment/success" component={BookingSuccessV2} />
              <Route exact path="/booking/payment/success/v2" component={BookingSuccess} />
              <Route exact path="/winner/payment/success" component={BookingWinnerSuccess} />
              <Route exact path="/entry" component={SingleEntryPage} />
              <Route exact path="/admin/entry" component={AdminSingleEntryPage} />
              <Route exact path="/award/default-entry" component={EntryDefaultPage} />
              <Route exact path="/award/defaulttest-entry" component={EntryDefaultTestPage} />
              {/* <Route exact path="/entry/edit" component={EditEntryPage} /> */}
              <Route exact path="/entry/edit" component={EntryPage} />
              <Route exact path="/booking/details" component={BookingDetailsPage} />

              <Route exact path="/booking/summary" component={CheckOutPageV2} />
              <Route exact path="/booking/summary/unlisted" component={CheckOutUnlistedPageV2} />

              <Route exact path="/booking/summary/v2" component={CheckOutPage} />
              <Route exact path="/booking/summary/unlisted/V2" component={CheckOutUnlistedPage} />

              <Route exact path="/winner/summary" component={CheckOutAwardWinnerPage} />
              <Route exact path="/booking/attendees" component={BookingAttendeesPage} />
              <Route exact path="/booking/attendees/setup" component={BookingAttendeesSetupPage} />
              <Route exact path="/" component={DashboardEntrantPage} />
              <Route exact path="/admin/venue/guest-list" component={AdminVenueGuestlistPage} />
              {/* <Route exact path="/admin/award-single-page" component={AdminSingleAwardPage} /> */}
              <Route exact path="/admin/user" component={AdminUserPage} />
              <Route exact path="/admin/judges" component={AdminJudgePage} />
              <Route exact path="/admin/coordinators" component={AdminCoordinatorPage} />
              <Route exact path="/admin/judge/allocation" component={AdminJudgeAllocationPage} />
              <Route exact path="/awards" component={AwardPage} />
              <Route exact path="/entries" component={EntriesPage} />
              <Route exact path="/coordinator/entries" component={EntriesPage} />
              <Route exact path="/judge/entries" component={EntriesPage} />
              <Route exact path="/bookings" component={BookingsPage} />
              <Route exact path="/admin" component={AdminDashboardPage} />
              <Route exact path="/admin/users" component={AdminUserManagementPage} />
              <Route exact path="/admin/awards" component={AdminAwardsPage} />
              <Route exact path="/admin/award-types" component={AdminAwardTypesPage} />
              <Route exact path="/admin/award" component={AdminSingleAwardPage} />
              <Route exact path="/admin/surveys" component={AdminSurveysPage} />
              <Route exact path="/admin/category" component={AdminSingleCategoryPage} />
              <Route exact path="/admin/category/create" component={CreateCategoryPage} />
              <Route exact path="/admin/entries" component={AdminEntriesPage} />
              <Route exact path="/admin/script-generator" component={AdminScriptGeneratorPage} />
              <Route exact path="/admin/script-template" component={AdminScriptTemplate} />
              <Route exact path="/admin/script-vo" component={AdminScriptVOPage} />
              <Route exact path="/admin/script-categories" component={AdminScriptCategoryOrder} />
              <Route exact path="/admin/script-comments" component={AdminScriptJudgeComments} />
              <Route exact path="/admin/script-final" component={AdminScriptFinal} />
              <Route exact path="/admin/scripts" component={ScriptBlurbPage} />
              <Route exact path="/admin/bookings" component={AdminBookingsPage} />
              <Route exact path="/admin/booking/details" component={AdminBookingDetailsPage} />
              <Route exact path="/admin/view-profile" component={ViewProfilePage} />
              <Route exact path="/admin/edit-profile" component={AdminEditProfilePage} />
              <Route exact path="/entrant/judging-panel" component={EntrantJudgingPanelPage} />
              <Route exact path="/judge/profile" component={JudgeProfilePage} />
              <Route exact path="/entrant/shortlist-benefits" component={EntrantShortlistBenefitsPage} />
              {/* <Route exact path="/coordinator/profile" component={CoordinatorProfilePage} /> */}
              {/* <Route exact path="/coordinator/shortlisting" component={CoordinatorShortlistingPage} /> */}
              <Route exact path="/coordinator/referral" component={JudgingCoordinatorReferralPage} />
              {/* <Route exact path="/judge/category" component={JudgeCategoryPage} /> */}
              <Route exact path="/judge/entry" component={JudgeEntryPage} />
              <Route exact path="/judge/judging" component={JudgeJudgingPage} />
              <Route exact path="/judge/assignment" component={JudgeAssignmentPage} />
              <Route exact path="/judge/assignment/category" component={JudgeAssignmentCategoryPage} />
              <Route exact path="/judge/shortlisting" component={JudgeShortlistingPage} />
              <Route exact path="/coordinator/assignments" component={CoordinatorAssignmentsPage} />
              <Route exact path="/coordinator/assignment" component={CoordinatorAssignmentPage} />
              <Route exact path="/coordinator/judge/allocation" component={JudgingCoordinatorJudgeAllocationResultsPage} />
              <Route exact path="/coordinator/assignment/category" component={CoordinatorAssignmentCategoryPage} />
              {/* <Route exact path="/coordinator/category" component={CoordinatorCategoryPage} /> */}
              <Route exact path="/invoices" component={InvoicesPage} />
              <Route exact path="/admin/judging-results" component={AdminJudgingResults} />
              <Route exact path="/admins" component={AdminsPage} />
              <Route exact path="/admin/entry/edit" component={AdminEntryEditPage} />
              <Route exact path="/admin/admins" component={AdminsV2Page} />
              <Route exact path="/notifications" component={NotificationPage} />
              <Route exact path="/admin/invoices" component={AdminInvoicesPage} />
              <Route exact path="/admin/price-quote/form" component={AdminPriceQuotePage} />
              <Route exact path="/admin/price-quote" component={AdminPriceQuotesPage} />
              <Route exact path="/admin/booking-request" component={AdminBookingRequestPage} />
              <Route exact path="/admin/create/price-quote" component={AdminCreatePriceQuotePage} />
              {/* <Route exact path="/admin/venue/seat-plan" component={AdminSeatPlanPage} /> */}
              <Route exact path="/entering-an-award" component={EnteringAwardPage} />
              <Route exact path="/admin/booking/attendees" component={AdminBookingAttendeesPage} />
              <Route exact path="/involvement" component={InvolvementPage} />
              <Route exact path="/entrant/category" component={EntrantCategoryPage} />
              <Route exact path="/entrant/bookings" component={EntrantBookingsPage} />
              <Route exact path="/award-event-information" component={AwardEventInformation} />
              <Route exact path="/be-a-sponsor" component={BeASponsorPage} />
              <Route exact path="/be-a-judge" component={BeAJudgePage} />
              <Route exact path="/judge/terms-and-condition" component={JudgeTermsAndConditionPage} />
              <Route exact path="/coordinator/terms-and-condition" component={CoordinatorTermsAndConditionPage} />
              <Route exact path="/judge/referral" component={JudgeReferralPage} />
              <Route exact path="/judge/category" component={JudgeJudgingCategoryPage} />
              <Route exact path="/entrant/entry/details" component={EntrantSingleEntryPage} />
              <Route exact path="/entrant/categories" component={EntrantCategoriesPage} />
              <Route exact path="/entrant/entries" component={EntrantEntriesPage} />
              <Route exact path="/social-media" component={SocialMediaPage} />
              <Route exact path="/entrant/gallery" component={GalleryPage} />
              <Route exact path="/entrant/socialmedia" component={SocialMediaConnectedAccountsPage} />
              <Route exact path="/coordinator/assignment-entries" component={CoordinatorAssignmentEntriesPage} />
              <Route exact path="/my-bookings" component={BookingV2Page} />
              <Route exact path="/entrant/booking" component={EntrantBookingPage} />
              <Route exact path="/entrant/price-quote" component={EntrantPriceQuotePage} />
              <Route exact path="/entrant/submit-entry" component={EntrantSubmitEntryPage} />
              <Route exact path="/entrant/confirm/trophy-recipient" component={EntrantConfirmTrophyRecipientPage} />
              <Route exact path="/admin/procurement" component={AdminProcurementPageV2} />
              <Route exact path="/admin/procurementV2" component={AdminProcurementPageV2} />
              <Route exact path="/admin/page-plan" component={AdminPagePlanPage} />
              <Route exact path="/admin/page-item" component={AdminPageItemPage} />
              <Route exact path="/admin/page-plan/edit" component={AdminPagePlanEditPage} />
              <Route exact path="/admin/artwork" component={AdminArtWorkPageV2} />
              <Route exact path="/admin/artworkV2" component={AdminArtWorkPageV2} />
              <Route exact path="/admin/artwork/edit" component={AdminArtWorkEditPage} />
              <Route exact path="/admin/artwork-type" component={AdminArtWorkTypePage} />
              <Route exact path="/entrant/home" component={EntrantHomePage} />
              <Route exact path="/entrant/entry-support" component={EntrantEntrySupportPage} />
              <Route exact path="/entrant/pre-consultation" component={EntrantPreConsultationPage} />
              <Route exact path="/entrant/chat" component={EntrantChatPage} />
              <Route exact path="/admin/award/chats" component={AdminAwardChatsPage} />
              <Route exact path="/admin/award/chat" component={AdminAwardChatPage} />
              <Route exact path="/entrant/make-winning-entry" component={EntrantWinningEntryPage} />
              <Route exact path="/entrant/judge/score-card" component={EntrantJudgeScoreCardPage} />
              <Route exact path="/entrant/sample-winning-entry" component={EntrantSampleWinningEntryPage} />
              <Route exact path="/entrant/awardprofile" component={EntrantAwardProfilePage} />
              <Route exact path="/judge/home" component={JudgeHomePage} />
              <Route exact path="/judge/categories" component={JudgeCategoriesPage} />
              <Route exact path="/judging/coordinator" component={JudgingCoordinatorPage} />
              <Route exact path="/judge/fellow-judges" component={JudgeFellowJudgesPage} />
              <Route exact path="/judge/complimentary-seats" component={JudgeComplimentarySeatsPage} />
              <Route exact path="/judge/instruction" component={JudgeInstructionsPage} />
              <Route exact path="/judge/nomination" component={JudgeNominationPage} />
              <Route exact path="/coordinator/home" component={JudgingCoordinatorHomePage} />
              <Route exact path="/coordinator/profile" component={JudgingCoordinatorProfilePage} />
              <Route exact path="/coordinator/shortlisting" component={JudgingCoordinatorShortlistingPage} />
              <Route exact path="/coordinator/category" component={JudgingCoordinatorShortlistingCategoryPage} />
              <Route exact path="/coordinator/judges" component={JudgingCoordinatorJudgesPage} />
              <Route exact path="/coordinator/fellow-coordinators" component={JudgingCoordinatorFellowCoordinatorsPage} />
              <Route exact path="/coordinator/instructions" component={JudgingCoordinatorInstructionsPage} />
              <Route exact path="/coordinator/complimentary-seats" component={JudgingCoordinatorComplimentarySeatsPage} />
              <Route exact path="/award/event" component={BookingAwardEventPage} />
              <Route exact path="/entrant/gallery/selections" component={GallerySelectionsPage} />
              <Route exact path="/entrant/gallery/selection" component={GallerySelectionPage} />
              <Route exact path="/entrant/gallery/timeline" component={GalleryTimelinePage} />
              <Route exact path="/admin/upload" component={AdminGalleryUploadPage} />
              <Route exact path="/stakeholders" component={StakeholdersPage} />
              <Route exact path="/stakeholders/artworks" component={StakeholdersArtworkPage} />
              <Route exact path="/admin/event/information" component={AdminEventInformationPage} />
              <Route exact path="/admin/venue/seating" component={AdminVenueSeatingPage} />
              <Route exact path="/admin/venue/plan" component={AdminVenuePlanPage} />
              <Route exact path="/admin/venue" component={AdminVenuePage} />
              <Route exact path="/admin/venue/settings" component={AdminVenueSettingsPage} />
              <Route exact path="/admin/venues" component={AdminVenuesPage} />
              {/* <Route exact path="/admin/edit/venue" component={AdminEditSeatingVenuePage} /> */}
              <Route exact path="/entrant/guest-list" component={EntrantManageMyAttendeesPage} />
              <Route exact path="/admin/winners/list" component={AdminWinnersListPage} />
              <Route exact path="/admin/entries/list" component={AdminEntriesListPage} />
              <Route exact path="/admin/create/invoice" component={AdminCreateInvoicePage} />
              <Route exact path="/admin/bank/transfers" component={AdminBankTransfersPage} />
              <Route exact path="/admin/booking/forms" component={AdminBookingFormsPage} />
              {/* <Route exact path="/admin/bank/transfers" component={AdminBankTransfersPage} /> */}
              <Route exact path="/payment/success" component={PaymentSuccess} />
              <Route exact path="/invoice/payment/success" component={BookingInvoiceSuccess} />
              <Route exact path="/entry/payment/success" component={EntryPaymentSuccess} />
              <Route exact path="/bank/transfer" component={BankTransferPage} />
              <Route exact path="/bank/transfer/terms" component={BankTransferTermsAndConditionPage} />
              <Route exact path="/tickets" component={TicketsPage} />
              <Route exact path="/admin/tickets" component={AdminTicketsPage} />
              <Route exact path="/admin/ticket" component={AdminSingleTicketPage} />
              <Route exact path="/entrant/performance-benchmark-report" component={EntrantPerformanceBenchmarkReportPage} />
              <Route exact path="/admin/performance-benchmark-report" component={EntrantPerformanceBenchmarkReportPage} />
              <Route exact path="/admin/shortlists" component={AdminShortlistsPage} />
              <Route exact path="/admin/articles" component={AdminAwardArticlesPage} />
              <Route exact path="/admin/articles/new" component={AdminAwardArticlesAddPage} />
              <Route exact path="/admin/articles/edit" component={AdminAwardArticlesEditPage} />
              <Route exact path="/admin/inquiries" component={AdminPreConsultationPage} />
              <Route exact path="/admin/collaterals" component={AdminCollateralsPage} />
              <Route exact path="/admin/collateral" component={AdminCollateralPage} />
              <Route exact path="/admin/artwork/collaterals" component={AdminArtworkCollateralsPage} />
              <Route exact path="/admin/artwork/collateral" component={AdminArtwokCollateralPage} />
              <Route exact path="/admin/award/cms" component={AdminAwardCMSCreatePage} />
              <Route exact path="/admin/award/cms/settings" component={AdminAwardCMSSettingsPage} />
              <Route exact path="/admin/award/cms/theme" component={AdminAwardCMSThemePage} />
              <Route exact path="/admin/award/cms/menu" component={AdminAwardCMSMenuPage} />
              <Route exact path="/admin/award/cms/pages" component={AdminAwardCMSPagesPage} />
              <Route exact path="/admin/award/cms/social-media" component={AdminAwardCMSSocialMediaPage} />
              <Route exact path="/admin/award/inquiries" component={AdminAwardInquiriesPage} />
              <Route exact path="/admin/sponsorship/types" component={AdminSponsorshipTypesPage} />
              <Route exact path="/admin/sponsorship/type" component={AdminSingleSponsorshipTypePage} />
              <Route exact path="/admin/sponsorship/features" component={AdminSponsorshipFeaturesPage} />
              <Route exact path="/admin/award/entry-guide" component={AdminEntryGuideGeneratorPage} />
              <Route exact path="/admin/award/event-guide" component={AdminEventGuidePage} />
              <Route exact path="/account/verification" component={ErrorInvitationPage} />
              <Route exact path="/admin/sponsors" component={AdminSponsorsPage} />
              <Route exact path="/admin/sponsors/dashboard" component={AdminSponsorsDashboardPage} />
              <Route exact path="/admin/sponsor/files" component={AdminSponsorsFilesPage} />
              <Route exact path="/admin/sponsor/new" component={AdminAddSponsorPage} />
              <Route exact path="/admin/sponsor/view" component={AdminViewSponsorPage} />
              <Route exact path="/admin/sponsor/edit" component={AdminEditSponsorPage} />
              <Route exact path="/admin/sponsor/activate" component={AdminActivationSponsorPage} />
              <Route exact path="/admin/sponsor/activity" component={AdminSponsorActivityPage} />
              {/*BACKUP PAGES*/}
              <Route exact path="/admin/print-management-backup" component={AdminProcurementPageBackUp} />
              <Route exact path="/admin/view/artwork-backup" component={AdminArtWorkPageBackUp} />
              <Route exact path="/sponsor/home" component={SponsorHomePage} />
              <Route exact path="/sponsor/dashboard" component={SponsorshipDashboardPage} />
              <Route exact path="/sponsor/agreement" component={SponsorshipAgreementPage} />
              <Route exact path="/sponsor/management" component={SponsorshipManagementPage} />
              <Route exact path="/sponsor/activation" component={SponsorshipActivationPage} />
              <Route exact path="/404" component={PageNotFound} />
              <Route exact path="/admin/award/bookings" component={AdminAwardBookingsPage}  />
              <Route exact path="/admin/notifications" component={AdminNotificationsTriggerPage}  />
              <Route exact path="/admin/notifications/settings" component={AdminNotificationFilePage}  />
              <Route exact path="/award/nomination" component={PublicNominationFormPage} />

              <Route exact path="/admin/award/library" component={AdminAwardMediaLibraryPage} />
              <Route exact path="/admin/award/advertisements" component={AdminAwardAdvertisement} />

              <Route exact path="/price-quote/summary" component={PriceQuoteSummaryPage} />
              <Route exact path="/admin/project-types" component={AdminProjectTypesPage} />
              <Route exact path="/admin/suppliers" component={AdminSuppliersPage} />
              <Route exact path="/admin/projects" component={AdminProcurementPageV3} />
              <Route exact path="/admin/project" component={AdminProcurementsPageV3} />
              <Route exact path="/admin/project/new-invoice" component={AdminProjectNewInvoicePage} />
              <Route exact path="/admin/award/placement/new" component={AdminAwardPlacementAddPage} />
              <Route exact path="/admin/award/advertisement/new" component={AdminAwardAdvertisementAddPage} />
              <Route exact path="/admin/project/invoice" component={AdminProjectInvoicePage} />
              <Route exact path="/admin/project/update-invoice" component={AdminProjectNewInvoicePage} />
              <Route exact path="/admin/supplier/projects" component={AdminSupplierProjectsPage} />

              <Route exact path="/admin/blacklist" component={AdminBlacklistPage} />
              <Route exact path="/admin/email/checker" component={AdminEmailTesterPage} />
              
              <Route exact path="/admin/award/feedbacks/forms" component={AdminAwardPostEventFeedBackAdminPage} />
              <Route exact path="/admin/award/feedbacks/list" component={AdminAwardPostEventFeedBackPage} />
              <Route exact path="/admin/award/feedback" component={AdminAwardSinglePostEventFeedBackPage} />
              
              <Route exact path="/admin/industries" component={AdminIndustriesPage} />
              <Route exact path="/admin/industry" component={AdminIndustryAwardTypesPage} />
              <Route exact path="/admin/industry/awards" component={AdminAwardTypesAwardPage} />
              
              </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </GAListener>
    </BrowserRouter>
      }
    </>
  );
}


const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppMain);
